import {
  Box, Button as MuiButton, Grid, Typography,
  useTheme
} from '@mui/material'
import {
  alpha,
  styled
} from '@mui/material/styles'
import {
  useRouter
} from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  useForm
} from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Button from '~components/Button'
import FormProvider, {
  RHFTextField
} from '~components/hook-form'
import Image from '~components/image'
import Loader from '~components/loader/Loader'
import OccasionCard from '~components/OccasionCard/OccasionCard'
import Reminder from '~components/Reminder/Reminder'
import SectionCard from '~components/SectionCard/SectionCard'
import SingersCard from '~components/SingersCard/SingersCard'
import Carousel2 from '~screens/home/components/Carousel2'
import ReviewCard from '~screens/home/components/ReviewCard'
import { IMAGES_PREFIX } from '~config/config-global'
import {
  bestRecipe,
  reminderDesc, stepsMini
} from '~config/constants'
import useResponsive from '~hooks/useResponsive'
import useStartSong from '~hooks/useStartSong'
import {
  useArtistsQuery
} from '~screens/artists/services/artistApi'
import {
  useOccasionQuery
} from '~screens/occasions/services/occasionsApi'
import forWhoSchema from '~screens/order/schema/for-who.schema'
import {
  setStep1FormData
} from '~screens/order/store/orderSlice'
import {
  closeYoutubeModal,
  openYoutubeModal
} from "~screens/shared/store/sharedSlice"
import {
  bgGradient
} from '~utils/cssStyles'
import { sendTaggingAddToCart } from '../../services/tagging'
import Carousel from './components/Carousel'
import FAQs from './components/FAQs'
import FlavourSectionSm from './components/FlavourSectionSm'
import PlayYoutubeModal from './components/PlayYoutubeModal'
import SliderButtons from './components/SliderButtons'
import VideoComponent from './components/VideoComponent'
import { useModalDialogs } from '~screens/shared/modal-dialogs/modal-dialogs-provider'
import { getQueryParam } from '~services/cookie'
import Link from '@mui/material/Link';



const StyledRoot = styled('div')(({
  theme: {
    breakpoints,
    palette: {
      header,
    }
  }
}: any) => ({
  position: 'relative',
  overflow: 'hidden',
  ...bgGradient({
    imageOnly: true,
    imgUrl: IMAGES_PREFIX + '/assets/images/auth-bg-image.png',
  }),
  maxWidth: '2600px',
  [breakpoints.up('md')]: {
    top: 0,
    //    left: 0,
    width: '100%',
    height: 'calc(100vh -65px)',
    position: 'absolute',
  },
  [breakpoints.down('md')]: {
    background:
      header.background,
  },
}))

const StyledEllipseTop
  = styled('div')(({
    theme: {
      palette: {
        primary,
      }
    }
  }) => ({
    position: 'absolute',
    width: 480,
    height: 480,
    top: '-80px',
    right: '-80px',
    borderRadius: '50%',
    filter: 'blur(100px)',
    WebkitFilter: 'blur(100px)',
    backgroundColor: alpha(
      primary.darker,
      0.12
    ),
  }))

const StyledEllipseBottom
  = styled('div')(({
    theme: {
      palette: {
        primary,
      }
    }
  }) => ({
    position: 'absolute',
    height: 400,
    bottom: -200,
    left: '10%',
    right: '10%',
    borderRadius: '50%',
    filter: 'blur(100px)',
    WebkitFilter: 'blur(100px)',
    backgroundColor: alpha(
      primary.darker,
      0.08
    ),
  }))

export default function Home() {

  const defaultValues = {
    recipientName: null,
  }

  const {
    palette: {
      primary,
      common,
      info,
      header,
    },
  }: any = useTheme()

  const { setIsInHome, showRegisterDialog, setHideDialogsCallback } = useModalDialogs();


  const {
    push,
    replace,
    pathname

  } = useRouter();


  const isDesktop = useResponsive('up', 'md')
  const isMobile = useResponsive('down', 'sm')
  const isTablet = useResponsive('up', 'sm') && !isDesktop


  const {
    data = {},
    isLoading: gettingArtists,
  } = useArtistsQuery({
    limit: 15,
    notOpen: true,
  })

  const {
    data: artists = [],
  } = data || {}

  const {
    data: occasions = [],
    isLoading: gettingOccasions,
  }: any = useOccasionQuery({
    limit: 8
  });

  const artistsRef
    = useRef<any>(null)
  const occasionsRef
    = useRef<any>(null)

  const dispatch
    = useDispatch()

  const {
    user
  } = useSelector((state: any) =>
    state.auth
  )

  const {
    youtubeModalURL,
    youtubeModalIsOpen
  } = useSelector((state: any) =>
    state.shared
  )

  const {
    startSong
  } = useStartSong()

  const methods = useForm({
    defaultValues,
    resolver: forWhoSchema,
  })

  const {
    handleSubmit,
    formState: {
      errors,
    }
  } = methods

  const [remindMeIsOpen, setRemindMeIsOpen]
    = useState(false)

  const handleStartSong = handleSubmit((data) => {
    dispatch(setStep1FormData({
      ...data,
    }))
    startSong({
      ...data,
    })

    sendTaggingAddToCart()
  })

  const renderArtists = artists?.slice(0, isMobile ? 3 : 30)?.map(({
    _id,
    pfpPath,
    firstName,
    lastName,
    stageName,
    musicTraits,
    artistSong = {},
    openForWork = false,
  }: any) => (
    <SingersCard
      key={_id}
      _id={_id}
      pfpPath={pfpPath}
      firstName={firstName}
      lastName={lastName}
      stageName={stageName}
      artistSong={artistSong}
      musicTraits={musicTraits}
      openForWork={openForWork}
    />
  ))

  const renderOccasions =
    occasions?.filter((obj: any) =>
      obj?.imagePath
    )?.slice(0, isMobile ? 3 : 8)?.map(({
      _id,
      name,
      imagePath,
      occasionSong = {},
    }: any) => (
      <OccasionCard
        key={_id}
        _id={_id}
        name={name}
        occasionSong={occasionSong}
        imagePath={imagePath}
      />
    ))







  const finishReferialMode = useCallback(() => {
    setIsInHome(false);
  }, []);
  useEffect(() => {
    if (user?.userDto?.role?.name !== "Visitor") return;
    let referral = getQueryParam('id');
    if (referral) {
      setHideDialogsCallback(finishReferialMode)
      replace(pathname);
      showRegisterDialog()
      setIsInHome(true);
      return () => {
        setIsInHome(false);
        setHideDialogsCallback(null)
      }
    }
  }, [user?.userDto?.role?.name])


  return (
    <Box
      sx={{

        maxWidth: '2600px',
        mx: 'auto',


      }}
    >
      <StyledRoot
        sx={{
          border: '0px solid red',
        }}
      >
        <Grid
          container
          display={'flex'}
          spacing={{ xs: 0, md: 10 }}
          sx={{
            height: 1,
            border: '0px solid red',
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
            paddingLeft: {
              xs: '5%',
              md: '0',
            },
          }}
          item
        >
          <Grid

            xs={12}
            md={6}
            sx={{
              height: 1,
              border: '0px solid red',
              display: 'flex',
              alignItems: 'center',
              pt: {
                md: '80px !important',
                xs: '16px !important',
              }
            }}
            item
          >
            <Box
              className="home-title-wrapper"
              sx={{
                pt: { xs: 0, sm: 0, md: '200px' },
                width: {
                  md: '516px',
                },
                border: '0px solid red',
                ml: {
                  md: '15%',
                  xs: '0%',
                },
                mr: '10px',
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  border: '0px solid red',
                  mb: '16px',
                  color: primary.main,
                  lineHeight: {
                    md: '64px',
                  },
                }}
              >
                {'Create Your Song, '}
                <span
                  style={{
                    color: common.black,
                  }}
                >
                  {'Pay Only If You Love It'}
                </span>
              </Typography>
              <Typography
                component={'div'}
                sx={{
                  border: '0px solid red',
                  mb: '42px',
                  fontSize: {
                    md: '28px',
                  },
                }}
              >
                Transform your special moments into a one-of-a-kind song with our new revolutionary service. Just fill out the details for your Custom Song, and in just a few minutes, you'll have your song ready to enjoy. All this for only $19.99
                <span style={{ fontSize: '0.75em', marginLeft: '8px' }}>(USD)</span>
                <Box sx={{ mt: 2 }}>
                  <Link
                    href="/artist/6666110242d0c15c1b7fc242/"
                    sx={{
                      border: '0px solid red',
                      mb: '8px',
                      fontSize: 16,
                      fontWeight: 'bold',
                      display: 'block',
                      textDecoration: 'underline', // Add underline
                    }}
                  >
                    Click to Hear How Good It Sounds
                  </Link>
                </Box>
              </Typography>

              <Typography
                component={'div'}
                sx={{
                  border: '0px solid red',
                  mb: '8px',
                  fontSize: 19,
                  fontWeight: 'bold',
                }}
              >
                Let’s begin
              </Typography>

              <FormProvider
                methods={methods}
              >
                <RHFTextField
                  name="recipientName"
                  label="Who is the song for?"
                  sx={{
                    border: '0px solid red',
                    mb: '24px !important',
                    borderRadius: 1,
                  }}
                  InputProps={{
                    sx: {

                      background:
                        'white',
                    },
                  }}
                />
              </FormProvider>

              <Button
                title='Start your song'
                loading={false}
                onClick={handleStartSong}
                sx={{
                  width: {
                    md: 'fit-content',
                    xs: '100%'
                  },
                  px: 8,
                  mb: {
                    md: 0,
                    xs: 5
                  },
                }}
              />
            </Box>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={6}
            sx={{
              border: '0px solid red',
              margin: 0,
              pl: { xs: 0, md: '22px !important' },
              pb: { xs: 3, md: 0 },
              pr: { xs: 3, md: 0 },
            }}
            item
          >
            <VideoComponent
              className='home-top-right-img'

              visibleByDefault
              alt="auth"
              src={isMobile ?
                'https://res.cloudinary.com/songoven/video/upload/website_assets/client.songoven.com/assets/videos/hompage7_kzuc9j.mp4'
                :
                'https://res.cloudinary.com/songoven/video/upload/website_assets/client.songoven.com/assets/videos/hompage7_kzuc9j.mp4'
              }
              videoStyles={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: '50% 20%',
              }}
              sx={{
                height: {
                  md: "100vh",
                  sm: "60vh",
                  xs: "265px",
                },
                marginRight: "0px",
                border: '0px solid red',
                ml: {
                  md: 0,
                  xs: 0,
                },
                borderTopLeftRadius: {
                  xs: 25,
                  md: 40,
                },
                borderBottomLeftRadius: {
                  xs: 25,
                  sm: 40,
                  md: 0,
                },

                borderTopRightRadius: {
                  xs: 25,
                  md: 0,
                },
                borderBottomRightRadius: {
                  xs: 25,
                  sm: 0,
                  md: 0,
                },
                mt: {
                  xs: 1,
                  md: 17,
                },
              }}
            />
          </Grid>
        </Grid>
        {isDesktop &&
          <StyledEllipseTop />
        }
        {
          //FIXME
          // <StyledEllipseBottom />
        }
      </StyledRoot>
      <Box
        sx={{
          height: {
            sm: '0',
            md: '100vh',
            border: '0px solid red',
          }
        }}
      />

      <Box
        className="home-grid-container"
        sx={{
          backgroundColor: (theme: any) =>
            theme.palette.primary.creamLight,
          margin: '0px',
          border: '0px solid red',
        }}
      >



        <Box
          sx={{
            backgroundColor: '#11202C',
            paddingRight: {
              md: '10px',
              xs: '',
            },
            margin: '0px',
          }}
        >
          <Grid
            container
            spacing={"16px"}
            sx={{
              display: {
                xs: 'none',
                lg: 'flex',
              },
              padding: '10px 0',
              paddingTop: {
                md: 19,
                xs: 0,
              },
            }}
            item
          >
            <Grid
              xs={12}
              md={6}
              alignSelf="flex-end"
              sx={{
                border: '0px solid red',
              }}
              item
            >
              <Box
                sx={{
                  border: '0px solid red',
                  ml: '180px',
                  width: '465px',
                  marginBottom: {
                    md: 13,
                    xs: 0,
                  },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    border: '0px solid red',
                    mb: '16px',
                    color: primary.main,
                    fontSize: {
                      xs: '32px',
                      md: '48px',
                    },
                  }}
                >
                  Songs add flavor to any event
                </Typography>
              </Box>
              <Grid
                xs={12}
                flexDirection="row"
                alignSelf="flex-end"
                display="flex"
                columnGap={0}
                item
              >
                <Grid
                  xs={6}
                  sx={{
                    marginLeft: {
                      xs: 0,
                      md: 0,
                    },
                    mr: "8px",
                  }}
                  item
                >
                  <SectionCard
                    onClick={
                      stepsMini[4].youtubeURL &&
                      (
                        () =>
                          dispatch(
                            openYoutubeModal(
                              stepsMini[4].youtubeURL
                            )
                          )
                      )
                    }
                    bgImg={stepsMini[4].image}
                    margin="20px 0 0 0"
                    height="770px"
                    sx={{
                      mb: '10px'
                    }}
                  />
                </Grid>
                <Grid
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    marginBottom: "8px",
                    border: '0px solid red',
                  }}
                  item
                >
                  <Typography
                    fontSize="20px"
                    fontWeight="700"
                    sx={{
                      width: '346px',
                      border: '0px solid red',
                      mb: '24px',

                      color:
                        primary.mainLight2,
                    }}
                  >
                    Making memories and spreading cheer with every song.
                  </Typography>
                  <SectionCard
                    onClick={
                      stepsMini[1].youtubeURL &&
                      (
                        () => dispatch(
                          openYoutubeModal(
                            stepsMini[1].youtubeURL
                          )
                        )
                      )
                    }
                    bgImg={stepsMini[1].image}
                    margin="5px 0 0 0"
                    height="590px"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              xs={3}
              sx={{
                border: '0px solid red',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flexDirection: 'column',
                marginBottom: "8px"
              }}
              item
            >
              <SectionCard
                onClick={
                  stepsMini[2].youtubeURL &&
                  (
                    () =>
                      dispatch(
                        openYoutubeModal(
                          stepsMini[2].youtubeURL
                        )
                      )
                  )
                }
                bgImg={stepsMini[2].image}
                margin="20px 0 0 0"
                height="570px"
                sx={{
                  mb: '10px'
                }}
              />
              <SectionCard
                onClick={
                  stepsMini[0].youtubeURL &&
                  (() =>
                    dispatch(
                      openYoutubeModal(
                        stepsMini[0].youtubeURL
                      )
                    )
                  )
                }
                bgImg={stepsMini[0].image}
                margin="10px 0 0 0"
                height="370px"
              />
            </Grid>
            <Grid
              alignSelf="flex-end"
              xs={3}
              sx={{
                border: '0px solid red',
                marginBottom: "8px",
              }}
              item
            >
              <SectionCard
                onClick={
                  stepsMini[3].youtubeURL &&
                  (() =>
                    dispatch(
                      openYoutubeModal(
                        stepsMini[3].youtubeURL
                      )
                    )
                  )
                }
                height="750px"
                bgImg={stepsMini[3].image}
                margin="0px"
              />
            </Grid>
          </Grid>
          <FlavourSectionSm />
        </Box>

        <Box
        >
          <Carousel2>

            <ReviewCard name={"Michael Davis"} review={"I ordered a song for my best friend's baby shower, and it was a riot! The production managed to blend our inside jokes with heartfelt wishes in the most delightful way. We were all laughing and crying at the same time. The song was the perfect mix of humor and sentiment, and it was the highlight of the party. From funny anecdotes about our high school days to touching wishes for the new baby, it covered everything. The chorus was hilarious and had everyone singing along. Thanks, Songoven, for turning my crazy ideas into a song that everyone adored! I will definitely be back for more."} date={"1 day ago"} stars={5} />

            <ReviewCard name={"Amelia Hall"} review={"I ordered a song for my brother's wedding, and it was perfect. The artist captured their love story beautifully. It was a unique and special gift that everyone loved. Thanks, Songoven!"} date={"2 day ago"} stars={5} />

            <ReviewCard name={"James Miller"} review={"I ordered a custom song for my son's graduation, and WOW! The results were beyond my wildest expectations. The artist nailed every detail, from his late-night study sessions to his soccer triumphs. The whole family was in awe, and there wasn't a dry eye in the room. This song will be a treasured memory for years to come. The process was incredibly smooth, and the final product was beyond our expectations, perfectly blending humor and heartfelt moments. Songoven, you’ve gained a customer for life! I can't wait to use your service again for future celebrations."} date={"2 day ago"} stars={5} />

            <ReviewCard name={"Sophia Anderson"} review={"The song we had made for our wedding was stunning. It perfectly captured our love story and added a magical touch to our big day. The artist's voice was amazing, and the entire experience was wonderful. We received so many compliments on the song, and it will be a cherished part of our wedding memories. Thank you, Songoven, for making our day even more special!"} date={"3 day ago"} stars={5} />

            <ReviewCard name={"William Brown"} review={"Songoven, you rock! The custom song for my daughter's sweet 16 was epic! It captured her personality and our relationship perfectly. She was over the moon, and her friends were all jealous. The lyrics were fun and energetic, just like her. Best gift ever! Thank you for making her birthday unforgettable. I can’t wait to see what you come up with next time!"} date={"3 day ago"} stars={5} />

            <ReviewCard name={"Emily Roberts"} review={"I wanted a song for my husband on our anniversary, and Songoven delivered beyond my wildest dreams. The artist beautifully captured our journey together, from the first time we met to the life we've built. When I played it for him, tears flowed freely, and we danced in our living room, reminiscing about our shared memories. The attention to detail in the lyrics was astonishing, and the melody was simply perfect. It felt like the artist had known us for years. It was an intimate, unforgettable moment that we will cherish forever. Thank you, Songoven, for making our special day even more magical. Your work is truly remarkable, and we can't wait to order another song for our next milestone."} date={"3 day ago"} stars={5} />

            <ReviewCard name={"Sarah Thompson"} review={"My husband’s retirement party needed something special, and Songoven delivered! The artist created a song that perfectly encapsulated his career and achievements, blending humor and nostalgia in a way that touched everyone. My husband was deeply moved and kept saying it was the best gift he ever received. We played it at the party, and it was the highlight of the evening. Our friends and family were so impressed, and many of them asked about Songoven. Thank you, Songoven, for making his retirement celebration unforgettable! This song will be a cherished reminder of his accomplishments and the love we all have for him."} date={"4 day ago"} stars={5} />

            <ReviewCard name={"David Moore"} review={"I was skeptical, but the song for our anniversary was perfect! The lyrics were heartfelt, and the artist’s voice was beautiful. It turned out way better than I imagined. Songoven, you made our day extra special. My partner and I were both moved to tears. Highly recommend! I will definitely be using your service again."} date={"4 day ago"} stars={5} />

            <ReviewCard name={"Henry Allen"} review={"Had an amazing experience creating a personal song for my wife. The singer has a phenomenal voice and was very kind throughout the process. The final song was beyond our expectations. Highly recommend Songoven for a unique gift idea!"} date={"4 day ago"} stars={5} />

            <ReviewCard name={"Emma Wilson"} review={"The Mother's Day song we ordered was incredible. The production really understood our feelings and created a beautiful tribute to our mom. It was the perfect gift, and she was so touched. The song brought back so many wonderful memories and made the day extra special. Thank you, Songoven, for making her day so special! We will definitely be recommending you to our friends and family."} date={"4 day ago"} stars={5} />

            <ReviewCard name={"Mia Taylor"} review={"The Valentine's Day song I ordered was fantastic! The artist beautifully captured our love story, and we both had tears of joy listening to it. It was the perfect way to celebrate our love. The entire process was seamless, and the final product was more than I could have hoped for. Thank you, Songoven!"} date={"4 day ago"} stars={5} />

            <ReviewCard name={"Ava Harris"} review={"The song created through Songoven was fantastic! It captured my inspiration perfectly. The singer’s voice was beautiful, and the production was amazing! Couldn’t be happier with the result. Thank you!"} date={"6 day ago"} stars={5} />

            <ReviewCard name={"Isabella Lewis"} review={"For my best friend’s engagement, the custom song was awesome. It nailed their love story, and everyone at the party loved it. Highly recommend Songoven!"} date={"6 day ago"} stars={5} />

            <ReviewCard name={"Noah Clark"} review={"I ordered a song for my mom's birthday, and it was beautiful. The lyrics captured our relationship perfectly. Mom was so moved, and it made her birthday incredibly special. Thank you, Songoven!"} date={"7 day ago"} stars={5} />

            <ReviewCard name={"Daniel Martinez"} review={"I got a custom song for my dad's birthday, and it was spot on! The artist mixed our shared memories and dad jokes into a hilarious yet touching song. We laughed, we cried, and we laughed some more. Dad loved it, and it was the highlight of his party. Thanks, Songoven, for making his birthday so memorable. I’m already thinking of what we can do for his next milestone!"} date={"7 day ago"} stars={5} />

            <ReviewCard name={"Linda Turner"} review={"For my parents' 50th wedding anniversary, I wanted something truly special. The song created by Songoven was a masterpiece that perfectly captured their love story. The artist wove their journey together into a beautiful melody that brought everyone to tears. It was a profound, moving experience that deeply touched us all. The song highlighted their resilience, love, and the beautiful life they built together over the decades. It became the centerpiece of our celebration, sparking stories and memories from their friends and family. I can't thank Songoven enough for this precious gift. You've created a piece of art that will be cherished by our family for generations."} date={"9 day ago"} stars={5} />

            <ReviewCard name={"Charlotte King"} review={"The Mother's Day song we ordered was amazing. The production captured our feelings perfectly. It was the best gift ever, and Mom loved it. Thanks, Songoven!"} date={"10 day ago"} stars={5} />

            <ReviewCard name={"Sophia Anderson"} review={"The song we had made for our wedding was stunning. It perfectly captured our love story and added a magical touch to our big day. The artist's voice was amazing, and the entire experience was wonderful. We received so many compliments on the song, and it will be a cherished part of our wedding memories. Thank you, Songoven, for making our day even more special!"} date={"12 day ago"} stars={5} />

            <ReviewCard name={"William Brown"} review={"Songoven, you rock! The custom song for my daughter's sweet 16 was epic! It captured her personality and our relationship perfectly. She was over the moon, and her friends were all jealous. The lyrics were fun and energetic, just like her. Best gift ever! Thank you for making her birthday unforgettable. I can’t wait to see what you come up with next time!"} date={"12 day ago"} stars={5} />

          </Carousel2>
        </Box>


        <Grid
          container
          spacing={1}
          sx={{
            overflow: 'hidden',
            height: {
              md: '690px',
              xs: undefined
            },
            border: '0px solid red',
            backgroundImage:
              'url(' + IMAGES_PREFIX + '/assets/images/home/stepsbg.png)',
            background: {
              md: 'unset',
              xs:
                header.background,
            },
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: {
              md: 'row',
              xs: 'column-reverse',
            },
            paddingTop: {
              md: '50px',
              xs: '0px',
            },
          }}
          item
        >
          <Grid
            xs={12}
            md={7}
            justifyContent="flex-end"
            alignItems="center"
            display="flex"
            sx={{
              border: '0px solid red',
            }}
            item
          >
            <Carousel />
          </Grid>
          <Grid
            xs={12}
            md={5}
            paddingLeft="0px"
            sx={{
              border: '0px solid red',
              display: 'flex',
              justifyContent: {
                md: 'flex-end',
                xs: 'flex-start'
              },
              borderRadius: {
                md: '20px 0px 0px 0px',
                xs: undefined,
              },
              overflow: 'hidden'
            }}
            item
          >
            <Image
              disabledEffect
              visibleByDefault
              alt="auth"
              src={IMAGES_PREFIX + '/assets/images/home/singer.png'}
              imageStyles={{
                objectFit: 'cover',
                border: '0px solid red',
              }}
              sx={{
                width: '100%',
                border: '0px solid red',
                borderRadius: {
                  md: ' 0px 0px 0px 0px',
                  xs: ' 0px 24px 24px 0px',
                },
                marginRight: {
                  xs: '48px',
                  md: '0',
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>


      <Grid
        container
        overflow-x="scroll"
        overflow-y="hidden"
        width="100%"
        display="flex"
        flexDirection="row-reverse"
        columnGap={1}
        sx={{
          padding: '10 0',
          border: '0px solid red',
        }}
        item
        paddingBottom={6}
        paddingTop={2}
      >
        <Grid
          md={10}
          xs={12}
          sx={{
            border: '0px solid red',
            marginTop: {
              md: '60px',
              xs: '40px',
            },
            marginRight: {
              md: 15,
              xs: 0,
            },
            marginBottom: {
              md: '54px',
              xs: '40px',
            },

            justifyContent: {
              md: 'flex-start',
              xs: 'center',
            },
          }}
          item
          display="flex"
          flexDirection="row-reverse"
        >
          <Typography
            variant="h2"
            sx={{
              border: '0px solid red',
              color: common.black,
            }}
          >
            Meet our artists
          </Typography>
        </Grid>
        {gettingArtists ?
          <Loader />
          :
          <Grid
            xs={12}
            sx={{
              position: 'relative',
              border: '0px solid red',
              scrollPaddingInlineStart: "200px",
              display: 'flex',
              justifyContent: {
                md: 'unset',
                xs: 'center',
              },
              ml: {
                xs: 0,
                md: 0,
              },
            }}
            item
          >
            <Box
              ref={artistsRef}
              columnGap={2}
              sx={{
                overflowX: 'auto',
                pl: {
                  xs: 0,
                  md: 22,
                },
                pr: {
                  xs: 0,
                  md: 5,
                },
                display: 'flex',
                border: '0px solid red',
                scrollBehavior: 'smooth',
                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {renderArtists}
            </Box>
            {!isMobile &&
              <SliderButtons
                ref={artistsRef}
              />
            }
          </Grid>
        }
        <Box
          marginTop={4}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: { md: "20px" },
            border: '0px solid red',
          }}
        >
          <MuiButton
            onClick={() => push('/artists')}
            sx={{
              '&:hover, &': {
                padding: "11px 33px",
                borderWidth: "2px",
              },
              width: {
                xs: '90%',
                md: '35%',
              },
              // Rest of your styles...
            }}
            variant="outlined"
          >
            Discover all artists
          </MuiButton>
        </Box>
      </Grid>
      <Box
        overflow="hidden"
        display="flex"
        columnGap={1}
        paddingBottom={6}
        sx={{
          height: {
            xs: 'unset',
            md: '885px'
          },
          border: '0px solid red',
          padding: '10 0',
          backgroundColor: 'rgba(50, 93, 127, 0.08)',
          flexDirection: 'column'
        }}
      >
        <Box
          marginRight={5}
          marginTop={4}
          marginBottom={4}
          display="flex"
          sx={{
            pl: {
              xs: 0,
              md: 22,
            },
            border: '0px solid red',
            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
        >
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              color: common.black,
              marginTop: {
                md: "50px",
                xs: "0px"
              }
            }}
          >
            A song for every occasion
          </Typography>
        </Box>
        {gettingOccasions ?
          <Loader />
          :
          <Box
            rowGap={2}
            sx={{
              position: 'relative',
              display: 'flex',
              mt: 4,
              scrollBehavior: 'smooth',
              border: '0px solid red',
              justifyContent: {
                md: 'unset',
                xs: 'center'
              },
            }}
          >
            <Box
              ref={occasionsRef}
              columnGap={2}
              sx={{
                overflowX: 'auto',
                pl: {
                  xs: 0,
                  md: 22,
                },
                pr: {
                  xs: 0,
                  md: 5,
                },
                display: 'flex',
                border: '0px solid red',
                scrollBehavior: 'smooth',
                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {renderOccasions}
            </Box>
            {!isMobile &&
              <SliderButtons
                ref={occasionsRef}
              />
            }
          </Box>
        }

        <Box
          marginTop={4}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: { md: "20px" },
            border: '0px solid red',
          }}
        >
          <MuiButton
            onClick={() => push('/occasions')}
            sx={{
              '&:hover, &': {
                padding: "11px 33px",
                borderWidth: "2px",
              },
              width: {
                xs: '90%',
                md: '35%',
              },
              // Rest of your styles...
            }}
            variant="outlined"
          >
            Discover all ocassions
          </MuiButton>
        </Box>
      </Box>
      <FAQs />
      <Grid
        container
        paddingTop={4}
        paddingBottom={4.5}
        xs={12}
        justifyContent="center"
        sx={{
          backgroundImage: {
            md:
              'url('
              +
              IMAGES_PREFIX
              +
              '/assets/images/home/reminderbg.png)',
            xs:
              'url('
              +
              IMAGES_PREFIX
              +
              '/assets/images/remind-me-bg-mobile.png)',
          },
          backgroundSize: 'cover',
          border: '0px solid red',
        }}
        item
      >
        <Grid
          xs={12}
          md={6}
          justifyContent="center"
          display="flex"
          alignItems="center"
          sx={{
            border: '0px solid red',
            mt: 2.5,
          }}
          item
        >
          <Grid
            md={5}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: {
                md: 'column',
                xs: 'column',
              },
            }}
            item
          >
            <Typography
              variant="h2"
              fontSize={48}
              fontWeight="900"
              sx={{
                border: '0px solid red',
                minWidth: 260,
                textAlign: {
                  md: 'left',
                  xs: 'center',
                },
                color: info.dark,
                fontSize: {
                  md: '48px',
                  xs: '32px',
                },
              }}
            >
              Remind me
            </Typography>
            <Typography
              sx={{
                textAlign: {
                  md: 'left',
                  xs: 'center',
                },
                my: {
                  xs: 2,
                  md: 2,
                },
              }}
            >
              {reminderDesc}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={6}
          item
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <Grid
            xs={12}
            md={8}
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: {
                md: 'flex-end',
                xs: 'center',
              },
            }}
            item
          >
            <MuiButton
              onClick={() => setRemindMeIsOpen(true)}
              variant="contained"
              size="large"
              sx={{
                color: 'white',
                marginTop: {
                  xs: 2,
                  md: '0px',
                },
                backgroundColor: 'rgba(132, 158, 178, 1)',
              }}
            >
              <Typography
                fontSize="15px"
                fontWeight="700"
                padding="0px 5px"
              >
                Save the date
              </Typography>
            </MuiButton>
          </Grid>
        </Grid>
      </Grid>
      <PlayYoutubeModal
        url={youtubeModalURL}
        isOpen={youtubeModalIsOpen}
        close={() => dispatch(closeYoutubeModal())}
      />
      <Reminder
        open={remindMeIsOpen}
        onReset={() => setRemindMeIsOpen(false)}
      />
    </Box>
  )
}
