import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Box, Link } from '@mui/material';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';

const ReviewCardWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  maxWidth: 345,
  margin: 'auto',
}));

const ReviewCard2 = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1, 0, 1, 0),
  borderRadius: theme.spacing(2),
  boxShadow: (theme.shadows as any)[3],
}));

const StarRating = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const VerifiedIconStyled = styled(VerifiedIcon)(({ theme }) => ({
  color: '#3897f1',
  marginLeft: theme.spacing(0.5),
}));

const ReviewContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ReviewAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: "#5c6bc0",
  color: '#FFFFFF',
  marginRight: theme.spacing(0.5), // Adjust this value to move the name more to the left
}));

const ReviewText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: '#4e4e4e',
}));

interface ReviewCardProps {
  name: string;
  review: string;
  date: string;
  stars: number;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ name, review, date = '1 day ago', stars = 5 }) => {
  const [showFullReview, setShowFullReview] = useState(false);
  const handleReadMore = () => {
    setShowFullReview(!showFullReview);
  };
  return (
    <ReviewCardWrapper>
      <ReviewCard2>
        <ReviewContent>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <ReviewAvatar>{name.charAt(0)}</ReviewAvatar>
            <Typography variant="h6" style={{ fontSize: '16px', fontWeight: 'bold' }}>
              {name}
            </Typography>
            <VerifiedIconStyled />
          </Box>
          <StarRating style={{ color: '#fed715' }}>
            {[...Array(stars)].map((_, index) => (
              <StarIcon key={index} />
            ))}
          </StarRating>

          <Box
            component="div"
            sx={{
              maxHeight: showFullReview ? 'none' : '8em', // adjust this value to control the max height // number of lines +1
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: showFullReview ? 'none' : 5, // controls how many lines to show before truncating // number of lines
              marginBottom: 0,
            }}
          >
            <ReviewText variant="body1">
              {review}
            </ReviewText>
          </Box>
          <Link
            component="button"
            variant="body2"
            onClick={handleReadMore}
            sx={{ cursor: 'pointer', mt: '1em', mb: '1em' }}
          >
            {showFullReview ? 'Read Less' : 'Read More'}
          </Link>
          <Typography variant="body2" color="#acacac">
            {date}
          </Typography>
        </ReviewContent>
      </ReviewCard2>
    </ReviewCardWrapper>
  );
};

export default ReviewCard;
