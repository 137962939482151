import {
    Box,
    Grid,
    Typography,
    useTheme
} from "@mui/material"
import {
    useEffect,
    useRef,
    useState
} from "react"
import CarouselDots from "~components/carousel-dots"
import SectionCard from "~components/SectionCard"
import { memories } from "~config/constants"
import {
    stepsMini
} from '~config/constants/lists'
import { useSelector, useDispatch } from 'react-redux';
import {
    // openYoutubeModal,
    //closeYoutubeModal,
    openYoutubeModal
} from "~screens/shared/store/sharedSlice"



function FlavourSectionSm() {

    const {
        palette: {
            primary,
        },
    }: any = useTheme()

    const touchStartX = useRef(null);
    const touchEndX = useRef(null);

    const [active, setActive]
        = useState(2)

    const dispatch = useDispatch()

    const {
        // youtubeModelURL, 
        youtubeModelIsOpen } = useSelector((state: any) => state.shared)

    const handleTouchStart = (e: any) => {
        touchStartX.current
            = e.touches?.[0]?.clientX;
    };

    const handleTouchMove = (e: any) => {
        touchEndX.current
            = e.touches?.[0]?.clientX;
    };

    const handleTouchEnd = () => {
        if (
            touchStartX.current
            &&
            touchEndX.current
        ) {
            const deltaX
                =
                touchEndX.current
                -
                touchStartX.current;

            if (deltaX > 0)
                handleSwap(true)
            else if (deltaX < 0)
                handleSwap(false)
        }

        touchStartX.current = null;
        touchEndX.current = null;
    };

    const handleSwap = (
        isPrev: boolean
    ) => {

        setActive((prev) => {

            if (
                (
                    isPrev
                    &&
                    prev == 0
                )
                ||
                (
                    !isPrev
                    &&
                    prev == stepsMini.length - 1
                )
            )
                return prev

            if (isPrev)
                return --prev
            else
                return ++prev
        })
    }

    return (
        <Box
            sx={{
                display: {
                    xs: 'flex',
                    lg: 'none',
                },
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: '16px',
                paddingTop: '80px',
                border: '0px solid red',
            }}
        >
            <Typography
                variant="h2"
                sx={{
                    border: '0px solid red',
                    mb: '16px',
                    color: primary.main,
                    textAlign: 'center',
                    mt: 3,
                    fontSize: {
                        xs: '32px',
                    },
                }}
            >
                Songs add flavor to any event
            </Typography>
            <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    border: '0px solid red',
                }}
                item
            >
                <Grid
                    item
                >
                    <Typography
                        sx={{
                            border: '0px solid red',
                            my: '2px',
                            mr: 2,
                            color: primary.contrastText,
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: '400',
                        }}
                    >
                        {memories}
                    </Typography>
                </Grid>
                <Grid
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    onMouseDown={handleTouchStart}
                    onMouseMove={handleTouchMove}
                    onMouseUp={handleTouchEnd}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        border: '0px solid red',
                        flexDirection: {
                            xs: 'column',
                        },
                        mt: '64px',
                    }}
                    item
                >
                    <SectionCard
                        onClick={stepsMini[active].youtubeURL && (() => dispatch(openYoutubeModal(stepsMini[active].youtubeURL)))}
                        bgImg={stepsMini[active].image}
                        margin="5px 0 0 0"
                        height="476px"
                    />
                    <Box sx={{ mt: '30px', mb: '48px' }}>
                        <CarouselDots
                            active={active}
                            setActive={setActive}
                            steps={stepsMini.length}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default FlavourSectionSm