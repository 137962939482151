import Head from 'next/head'
import AuthGuard from '~auth/AuthGuard'
import ScrollProgress from '~components/scroll-progress'
import MainLayout from '~layouts/main'
import {
  Home
} from '~screens/home'

HomePage.getLayout =
  (page: React.ReactElement) =>
    <MainLayout>
      {page}
    </MainLayout>

export default function HomePage() {
  return (
    <>
      <Head>
        <title>Give them an emotional custom song created by songoven</title>
        {/* Open Graph / Facebook Meta Tags */}
        <meta property="fb:app_id" key="fb:app_id" content="1325462581491521" />';
        <meta property="og:type" key="og:type" content="website" />
        <meta property="og:image" key="og:image" content="https://res.cloudinary.com/songoven/image/upload/main/facebook_share_pic.jpg" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" key="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" key="twitter:image" content="https://res.cloudinary.com/songoven/image/upload/main/facebook_share_pic.jpg" />
        { /* <meta name="songoven-fetched-data"  key="songoven-fetched-data"content={JSON.stringify(data)} /> */}

      </Head>
      <ScrollProgress />
      <AuthGuard>
        <Home />
      </AuthGuard>
    </>
  )
}
